exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cms-js": () => import("./../../../src/pages/cms.js" /* webpackChunkName: "component---src-pages-cms-js" */),
  "component---src-pages-crm-js": () => import("./../../../src/pages/crm.js" /* webpackChunkName: "component---src-pages-crm-js" */),
  "component---src-pages-fashion-mobile-app-js": () => import("./../../../src/pages/fashion-mobile-app.js" /* webpackChunkName: "component---src-pages-fashion-mobile-app-js" */),
  "component---src-pages-house-keeping-js": () => import("./../../../src/pages/house-keeping.js" /* webpackChunkName: "component---src-pages-house-keeping-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-video-downloader-js": () => import("./../../../src/pages/video-downloader.js" /* webpackChunkName: "component---src-pages-video-downloader-js" */)
}

